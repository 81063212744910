import VuexORM from '@vuex-orm/core'
import Vue from 'vue'
import Vuex from 'vuex'

import { $issueStatus, $snackbar, $reset } from '@hexway/shared-front'

import { axiosInstance as axios } from '../api'
import bus from '../bus'
import { handleError } from '../helpers'

import database from './orm'
import IssueCounter from './orm/issueCounter'

import ajaxBar from './modules/ajaxBar'
import appConnections from './modules/appConnections'
import appDrawer from './modules/appDrawer'
import botUser from './modules/botUser'
import confirm from './modules/confirm'
import defaultLayout from './modules/defaultLayout'
import ext, { watch as extModuleWatch } from './modules/ext'
import integration from './modules/integration'
import issueChat from './modules/issueChat'
import issueSchema from './modules/issueSchema'
import ldapConfigEditPage from './modules/ldapConfigEditPage'
import notification, { watch as notificationModuleWatch } from './modules/notification'
import permission from './modules/permission'
import projectConnections from './modules/projectConnections'
import projectsSettings from './modules/projectsSettings'
import report from './modules/report'
import service from './modules/service'
import user, { watch as userModuleWatch } from './modules/user'
import checklist from './modules/checklist'
import license from './modules/license'

Vue.use(Vuex)
// https://github.com/vuex-orm/vuex-orm/issues/714#issuecomment-810631707
VuexORM.use({
  install(components) {
    components.MorphMany.prototype.attach = function(key, record, data) {
      const relatedItems = data[this.related.entity]

      key.forEach((id) => {
        const relatedItem = relatedItems[id]

        relatedItem[this.id] =
          relatedItem[this.id] || this.model.getIdFromRecord(record)
        relatedItem[this.type] = relatedItem[this.type] || this.model.entity
      })
    }
  },
})

export default ({
  // inject real implementation (`@/i18n` default export)
  i18n = {
    t: msg => msg,
    tc: (msg, n) => `${n} ${msg}s`,
  },
} = {}) => {
  const errorHandler = (e, fallbackMessage) =>
    handleError(store, e, fallbackMessage)

  const store = new Vuex.Store({
    plugins: [
      VuexORM.install(database),
    ],

    modules: {
      $issueStatus: $issueStatus({
        axios,
        errorHandler,
        getSharedStatuses: '/issues/statuses/',
        getProjectStatuses: '/project/{projectId}/status/',
        postProjectStatus: ['post', '/project/{projectId}/status/'],
        patchProjectStatus: ['patch', '/project/{projectId}/status/{statusName}'],
        deleteProjectStatus: ['delete', '/project/{projectId}/status/{statusName}'],
        getTemplates: '/service/status_templates/',
        postTemplate: ['post', '/service/status_templates/'],
        patchTemplate: ['patch', '/service/status_templates/{statusName}/'],
        deleteTemplate: ['delete', '/service/status_templates/{statusName}/'],
        getIssuesCount: (state, getters, { projectId, statusName }) =>
          IssueCounter.getOrDefault(projectId, {}).status[statusName] ?? null,
        getGoToStatusRoute: (state, getters, { projectId, status }) => ({
          name: 'ProjectIssueList',
          params: { projectId },
          query: { status: status.name },
        }),
      }),
      $snackbar: $snackbar({ bus }),

      ajaxBar,
      appConnections,
      appDrawer,
      botUser,
      confirm,
      defaultLayout,
      ext,
      integration,
      issueChat,
      issueSchema,
      ldapConfigEditPage,
      notification,
      permission,
      projectConnections,
      projectsSettings,
      report,
      service,
      user,
      checklist,
      license,
    },

    getters: {
      title: (_, getters) =>
        title => {
          const withSuffix = title ? `${title} – Apiary` : 'Apiary'
          const unreadNotificationsCount = getters['notification/unreadCount']
          return unreadNotificationsCount
            ? [
              `*${i18n.tc('notification.newNotificationN', unreadNotificationsCount)}*`,
              withSuffix,
            ].join(' – ')
            : withSuffix
        },
    },

    actions: {
      reset: async ({ dispatch, commit }, full = false) => {
        if (full) commit('user/logOut')
        commit('appConnections/reset', full)
        commit('appDrawer/reset', full)
        commit('botUser/reset')
        commit('confirm/reset', full)
        commit('integration/reset', full)
        commit('issueChat/reset', full)
        commit('issueSchema/reset', full)
        commit('ldapConfigEditPage/reset', full)
        commit('notification/reset', full)
        commit('permission/reset', full)
        commit('projectConnections/reset', full)
        commit('projectsSettings/reset', full)
        commit('report/reset', full)
        commit('service/reset', full)
        commit('user/reset', full)
        commit('checklist/reset', full)
        commit('license/reset', full)

        $reset(commit, full)

        // clear vuex-orm store
        await dispatch('entities/deleteAll')
      },
    },
  })
  extModuleWatch(store)
  notificationModuleWatch(store)
  userModuleWatch(store)
  return store
}

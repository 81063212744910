import * as R from 'ramda'

import { NON_FILTER_PARAMS } from '../constants'
import DefaultLayout from '../layouts/DefaultLayout'
import Auth from '../views/Auth'

const SimpleRouterViews = {
  default: { render: h => h('router-view') },
  appDrawer: { render: h => h('router-view', { props: { name: 'appDrawer' } }) },
  outsideMain: { render: h => h('router-view', { props: { name: 'outsideMain' } }) },
}

// eslint-disable-next-line max-lines-per-function
export default ({ store }) => {
  const adminOnly = {
    beforeEnter: (to, from, next) => {
      const currentUser = () => store.getters['user/current']
      if (currentUser() && !currentUser()?.isAdmin) {
        if (to?.params?.projectId) {
          return next({
            name: 'Project',
            params: { projectId: to.params.projectId },
          })
        }
        return next('/')
      }
      return next()
    },
  }

  return [
    // Routes for all users
    {
      path: '',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          redirect: { name: 'Dashboard' },
        },
        {
          path: '/auth',
          name: 'Auth',
          component: Auth,
          meta: { public: true },
          props: ({ query: { next } }) =>
            next ? { next } : {},
        },
        {
          path: '/register',
          name: 'Register',
          component: Auth,
          meta: { public: true },
          props: ({ query: { next } }) =>
            next ? { next, register: true } : { register: true },
        },

        {
          path: '/dashboard',
          components: SimpleRouterViews,
          beforeEnter: async (to, from, next) => {
            await store.dispatch('license/getInformation', { reload: false })
            if (!store.getters['license/featureActive']('crossProjectDashboard')) {
              return next({ name: 'Projects' })
            }
            return next()
          },
          children: [
            {
              path: '', // /dashboard
              name: 'Dashboard',
              components: {
                default: () => import(
                  /* webpackChunkName: "dashboard" */
                  '../views/Dashboard'),
              },
              props: {
                default: ({ query: { cardId } }) => ({
                  highlightCardId: cardId ? Number(cardId) : null,
                }),
              },
            },

            {
              path: 'config', // /dashboard/config
              name: 'DashboardManage',
              components: {
                default: () => import(
                  /* webpackChunkName: "dashboard-manage" */
                  '../views/DashboardManage'),
              },
            },

            {
              path: 'card', // /dashboard/card
              name: 'EditCard',
              components: {
                default: () => import(
                  /* webpackChunkName: "edit-card" */
                  '../views/EditCard'),
                appDrawer: () => import(
                  /* webpackChunkName: "filters-drawer" */
                  '../views/FiltersDrawer'),
              },
              props: {
                default: ({ query }) => ({
                  checklistId: query.checklistId || null,
                  filter: R.omit(NON_FILTER_PARAMS, R.clone(query)),
                  cardId: query.cardId && !Number.isNaN(query.cardId)
                    ? parseInt(query.cardId, 10)
                    : null,
                  back: query.back || null,
                  forceCreate: query.copy === 'true',
                }),
                appDrawer: ({ query }) => ({
                  createCard: true,
                  filterQuery: R.omit(NON_FILTER_PARAMS, R.clone(query)),
                }),
              },
              meta: {
                appDrawer: true,
              },
            },

            {
              path: 'issues', // /dashboard/issues
              name: 'IssueList',
              components: {
                default: () => import(
                  /* webpackChunkName: "issue-list" */
                  '../views/IssueList'),
                appDrawer: () => import(
                  /* webpackChunkName: "filters-drawer" */
                  '../views/FiltersDrawer'),
              },
              props: {
                default: ({ query }) => ({
                  cardId: query.cardId
                    ? Number(query.cardId)
                    : null,
                  filter: R.omit(NON_FILTER_PARAMS, query),
                  groupingValue: query.groupingValue ? String(query.groupingValue) : null,
                  subQuery: query.subQuery ? String(query.subQuery) : '',
                  inlineIssueId: query.issueId || null,
                }),
                appDrawer: ({ query }) => ({
                  cardId: query.cardId ? Number(query.cardId) : null,
                  filterQuery: R.omit(NON_FILTER_PARAMS, query),
                }),
              },
              meta: {
                appDrawer: true,
              },
            },
            {
              path: 'issues/:issueId', // /dashboard/issues/:issueId
              name: 'IssueListIssue',
              components: {
                default: () => import(
                  /* webpackChunkName: "issue" */
                  '../views/Issue'),
                outsideMain: () => import(
                  /* webpackChunkName: "issue-outside-main" */
                  '../views/IssueChatDrawer'),
              },
              props: {
                default: ({ params: { issueId }, query }) => ({
                  issueId: issueId,
                  filter: R.omit(NON_FILTER_PARAMS, query),
                  chatDrawer: query.chat === 'on',
                }),
                outsideMain: ({ params: { issueId }, query: { chat } }) =>
                  ({ issueId, value: chat === 'on' }),
              },
            },

            {
              path: 'card/:cardId/issues', // /dashboard/card/:cardId/issues
              name: 'CardIssueList',
              components: {
                default: () => import(
                  /* webpackChunkName: "issue-list" */
                  '../views/IssueList'),
                appDrawer: () => import(
                  /* webpackChunkName: "filters-drawer" */
                  '../views/FiltersDrawer'),
              },
              props: {
                default: ({ params: { cardId }, query }) => ({
                  cardId: Number(cardId),
                  filter: R.omit(NON_FILTER_PARAMS, query),
                  groupingValue: query.groupingValue ? String(query.groupingValue) : null,
                  subQuery: query.subQuery ? String(query.subQuery) : '',
                  inlineIssueId: query.issueId || null,
                }),
                appDrawer: ({ params: { cardId }, query }) => ({
                  cardId: Number(cardId),
                  filterQuery: R.omit(NON_FILTER_PARAMS, query),
                }),
              },
              meta: {
                appDrawer: true,
              },
            },
            {
              path: 'card/:cardId/issues/:issueId', // /dashboard/card/:cardId/issues/:issueId
              name: 'CardIssueListIssue',
              components: {
                default: () => import(
                  /* webpackChunkName: "issue" */
                  '../views/Issue'),
                outsideMain: () => import(
                  /* webpackChunkName: "issue-outside-main" */
                  '../views/IssueChatDrawer'),
              },
              props: {
                default: ({ params: { issueId, cardId }, query }) => ({
                  issueId,
                  cardId: Number(cardId),
                  filter: R.omit(NON_FILTER_PARAMS, query),
                }),
                outsideMain: ({ params: { issueId }, query: { chat } }) =>
                  ({ issueId, value: chat === 'on' }),
              },
            },
          ],
        },

        {
          path: '/projects',
          components: SimpleRouterViews,
          children: [
            {
              path: '', // /projects
              name: 'Projects',
              components: {
                default: () => import(
                  /* webpackChunkName: "projects" */
                  '../views/Projects'),
              },
            },

            {
              path: 'create', // projects/create
              name: 'ProjectCreate',
              components: {
                default: () => import(
                  /* webpackChunkName: "project-edit" */
                  '../views/ProjectEdit'),
              },
              props: {
                default: ({ query: { appConnectionId } }) => ({
                  appConnectionId: appConnectionId,
                }),
              },
            },

            {
              path: ':projectId', // /projects/:projectId
              components: SimpleRouterViews,
              children: [
                {
                  path: '', // /projects/:projectId
                  name: 'Project',
                  redirect: (to) => ({
                    name: 'ProjectDashboard',
                    params: { projectId: to.params.projectId },
                  }),
                },

                {
                  path: 'edit', // /project/:projectId/edit
                  name: 'ProjectEdit',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "project-edit" */
                      '../views/ProjectEdit'),
                  },
                  props: {
                    default: ({ params: { projectId } }) => ({
                      projectId,
                    }),
                  },
                },

                {
                  path: 'dashboard', // /projects/:projectId/dashboard
                  name: 'ProjectDashboard',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "dashboard" */
                      '../views/Dashboard'),
                  },
                  props: {
                    default: ({ params: { projectId }, query: { cardId } }) => ({
                      projectId,
                      highlightCardId: cardId ? Number(cardId) : null,
                    }),
                  },
                },

                {
                  path: 'dashboard/config', // /projects/:projectId/dashboard/config
                  name: 'ProjectDashboardManage',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "dashboard-manage" */
                      '../views/DashboardManage'),
                  },
                  props: {
                    default: ({ params: { projectId } }) => ({ projectId }),
                  },
                },

                {
                  path: 'card', // /projects/:projectId/card
                  name: 'ProjectEditCard',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "edit-card" */
                      '../views/EditCard'),
                    appDrawer: () => import(
                      /* webpackChunkName: "filters-drawer" */
                      '../views/FiltersDrawer'),
                  },
                  props: {
                    default: ({ params: { projectId }, query }) => ({
                      projectId,
                      checklistId: query.checklistId || null,
                      filter: R.omit(NON_FILTER_PARAMS, query),
                      cardId: query.cardId && !Number.isNaN(query.cardId)
                        ? parseInt(query.cardId, 10)
                        : null,
                      back: query.back || null,
                      forceCreate: query.copy === 'true',
                    }),
                    appDrawer: ({ params: { projectId }, query }) => ({
                      projectId,
                      createCard: true,
                      filterQuery: R.omit(NON_FILTER_PARAMS, query),
                    }),
                  },
                  meta: {
                    appDrawer: true,
                  },
                },

                {
                  path: 'issues', // /projects/:projectId/issues
                  name: 'ProjectIssueList',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "issue-list" */
                      '../views/IssueList'),
                    appDrawer: () => import(
                      /* webpackChunkName: "filters-drawer" */
                      '../views/FiltersDrawer'),
                  },
                  props: {
                    default: ({ query, params: { projectId } }) => ({
                      projectId,
                      cardId: query.cardId
                        ? Number(query.cardId)
                        : null,
                      filter: R.omit(NON_FILTER_PARAMS, query),
                      groupingValue: query.groupingValue ? String(query.groupingValue) : null,
                      subQuery: query.subQuery ? String(query.subQuery) : '',
                      inlineIssueId: query.issueId || null,
                    }),
                    appDrawer: ({ params: { projectId }, query }) => ({
                      projectId,
                      cardId: query.cardId ? Number(query.cardId) : null,
                      filterQuery: R.omit(NON_FILTER_PARAMS, query),
                    }),
                  },
                  meta: {
                    appDrawer: true,
                  },
                },
                {
                  path: 'issues/:issueId', // /projects/:projectId/issues/:issueId
                  name: 'ProjectIssueListIssue',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "issue" */
                      '../views/Issue'),
                    outsideMain: () => import(
                      /* webpackChunkName: "issue-outside-main" */
                      '../views/IssueChatDrawer'),
                  },
                  props: {
                    default: ({ params: { issueId, projectId }, query }) => ({
                      projectId,
                      issueId: issueId,
                      filter: R.omit(NON_FILTER_PARAMS, query),
                      chatDrawer: query.chat === 'on',
                    }),
                    outsideMain: ({ params: { issueId }, query: { chat } }) =>
                      ({ issueId, value: chat === 'on' }),
                  },
                },

                {
                  path: 'card/:cardId/issues', // /projects/:projectId/card/:cardId/issues
                  name: 'ProjectCardIssueList',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "issue-list" */
                      '../views/IssueList'),
                    appDrawer: () => import(
                      /* webpackChunkName: "filters-drawer" */
                      '../views/FiltersDrawer'),
                  },
                  props: {
                    default: ({ params: { cardId, projectId }, query }) => ({
                      projectId,
                      cardId: Number(cardId),
                      filter: R.omit(NON_FILTER_PARAMS, query),
                      groupingValue: query.groupingValue ? String(query.groupingValue) : null,
                      subQuery: query.subQuery ? String(query.subQuery) : '',
                      inlineIssueId: query.issueId || null,
                    }),
                    appDrawer: ({ params: { cardId, projectId }, query }) => ({
                      projectId,
                      cardId: Number(cardId),
                      filterQuery: R.omit(NON_FILTER_PARAMS, query),
                    }),
                  },
                  meta: {
                    appDrawer: true,
                  },
                },
                {
                  path: 'card/:cardId/issues/:issueId', // /projects/:projectId/card/:cardId/issues/:issueId
                  name: 'ProjectCardIssueListIssue',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "issue" */
                      '../views/Issue'),
                    outsideMain: () => import(
                      /* webpackChunkName: "issue-outside-main" */
                      '../views/IssueChatDrawer'),
                  },
                  props: {
                    default: ({ params: { issueId, cardId, projectId }, query }) => ({
                      projectId,
                      issueId,
                      cardId: Number(cardId),
                      filter: R.omit(NON_FILTER_PARAMS, query),
                    }),
                    outsideMain: ({ params: { issueId }, query: { chat } }) =>
                      ({ issueId, value: chat === 'on' }),
                  },
                },

                {
                  path: 'checklists', // /projects/:projectId/checklists
                  name: 'ChecklistsList',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "checklists" */
                      '../views/ChecklistsList'),
                  },
                  props: {
                    default: ({ params: { projectId, checklistId } }) => ({
                      projectId,
                      checklistId,
                    }),
                  },
                },
                {
                  path: 'checklists/:checklistId', // /projects/:projectId/checklists/:checklistId
                  name: 'ChecklistsListChecklist',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "checklists" */
                      '../views/ChecklistsList'),
                  },
                  props: {
                    default: ({ params: { checklistId, projectId } }) => ({
                      projectId,
                      checklistId: checklistId,
                    }),
                  },
                },

                {
                  path: 'sla', // /projects/:projectId/sla
                  name: 'ProjectSlaConfig',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "global-sla-config" */
                      '../views/SlaConfig'),
                  },
                  props: {
                    default: ({ params: { projectId } }) => ({ projectId }),
                  },
                },
                {
                  ...adminOnly,
                  path: 'integration', // /projects/:projectId/integration
                  name: 'Integration',
                  components: {
                    default: () => import(
                      /* webpackChunkName: "integration" */
                      '../views/Integration'),
                  },
                  props: {
                    default: ({
                      params: { projectId },
                      query: { dialog, integration: integrationId, type: integrationCode },
                    }) => ({
                      projectId,
                      integrationDialog: dialog === 'integration',
                      integrationId: integrationId || null,
                      integrationCode: integrationCode || null,
                    }),
                  },
                },
              ],
            },
          ],
        },

        {
          ...adminOnly,
          path: '/admin',
          components: SimpleRouterViews,
          children: [
            {
              path: '', // /admin
              name: 'Admin',
              redirect: { name: 'License' },
            },
            {
              path: 'users', // /admin/users
              name: 'Users',
              components: {
                default: () => import(
                  /* webpackChunkName: "users" */
                  '../views/Users'),
              },
              props: {
                default: ({ query: { q: searchQuery, userId, botId, groupId, action } }) => ({
                  showUserDialog: ['create-user', 'edit-user'].includes(action),
                  dialogUserId: userId || null,
                  dialogBotId: botId || null,
                  showGroupDialog: ['create-group', 'create-group-from-selection', 'edit-group'].includes(action),
                  dialogGroupId: groupId || null,
                  fromSelection: action === 'create-group-from-selection',
                  searchQuery,
                }),
              },
            },
            {
              path: 'users/groups', // /admin/users/groups
              name: 'UserGroups',
              components: {
                default: () => import(
                  /* webpackChunkName: "user-groups" */
                  '../views/UserGroups'),
              },
              props: {
                default: ({ query: { q: searchQuery, action, groupId, userId } }) => ({
                  showGroupDialog: ['create-group', 'edit-group'].includes(action),
                  dialogGroupId: groupId || null,
                  showUserDialog: ['create-user', 'edit-user'].includes(action),
                  dialogUserId: userId || null,
                  searchQuery,
                }),
              },
            },
            {
              path: 'integration-servers', // /admin/integration-servers
              name: 'IntegrationServers',
              components: {
                default: () => import(
                  /* webpackChunkName: "integration" */
                  '../views/IntegrationServers'),
              },
              props: {
                default: ({
                  query: { action, serverId },
                }) => ({
                  integrationDialog: ['create', 'edit'].includes(action),
                  serverId: serverId || null,
                }),
              },
            },
            {
              path: 'ldap-config', // /admin/ldap-config
              name: 'LdapConfig',
              components: {
                default: () => import(
                  /* webpackChunkName: "ldap-config" */
                  '../views/LdapConfig'),
              },
            },
            {
              path: 'ldap-config/settings', // /admin/ldap-config/settings
              name: 'LdapConfigEdit',
              components: {
                default: () => import(
                  /* webpackChunkName: "ldap-config-edit" */
                  '../views/LdapConfigEdit'),
              },
            },
            {
              path: 'app-connections', // /admin/app-connections
              name: 'AppConnections',
              components: {
                default: () => import(
                  /* webpackChunkName: "app-connections" */
                  '../views/AppConnections'),
              },
            },
            {
              path: 'smtp-config', // /admin/smtp-config
              name: 'SmtpConfig',
              components: {
                default: () => import(
                  /* webpackChunkName: "smtp-config" */
                  '../views/SmtpConfig'),
              },
            },
            {
              path: 'license', // /admin/license
              name: 'License',
              components: {
                default: () => import(
                  /* webpackChunkName: "ldap-config" */
                  '../views/LicensePage'),
              },
            },
            {
              path: 'status', // /admin/status
              name: 'GlobalIssueStatus',
              components: {
                default: () => import(
                  /* webpackChunkName: "global-issue-status" */
                  '../views/GlobalIssueStatus'),
              },
            },
            {
              path: 'sla', // /admin/sla
              name: 'GlobalSlaConfig',
              components: {
                default: () => import(
                  /* webpackChunkName: "global-sla-config" */
                  '../views/SlaConfig'),
              },
            },
            {
              path: 'report-templates', // /admin/report-templates
              name: 'ReportTemplates',
              components: {
                default: () => import(
                  /* webpackChunkName: "report-templates" */
                  '../views/ReportTemplates'),
              },
            },
          ],
        },
      ],
    },

    // backward compatibility redirects
    { path: '/users', redirect: '/admin/users' },
    { path: '/license', redirect: '/admin/license' },
    { path: '/report-templates', redirect: '/admin/report-templates' },
    { path: '/ldap-config', redirect: '/admin/ldap-config' },
    { path: '/ldap-config/settings', redirect: '/admin/ldap-config/settings' },
    { path: '/integration-servers', redirect: '/admin/integration-servers' },

    {
      path: '*',
      name: 'Error404',
      component: () => import(/* webpackChunkName: "error404" */ '../views/Error404'),
    },
  ]
}

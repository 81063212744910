<template>
  <router-view />
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss'
import 'roboto-fontface/css/roboto-slab/sass/roboto-slab-fontface.scss'

export default {
  name: 'App',

  metaInfo() {
    return {
      title: this.$store.getters.title(''),
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
  },

  // bugfix-4062
  // watch: {
  //   currentUser: {
  //     handler(user) {
  //       if (!user) return
  //
  //       // logged in - load common data
  //       this.loadCommonData()
  //     },
  //     immediate: true,
  //   },
  // },

  // methods: {
  //   // requests required for each or almost each view
  //   async loadCommonData() {
  //     await this.$store.dispatch('projectsSettings/get')
  //   },
  // },
}
</script>

<style lang="sass">
@import 'scss/main'
@import 'scss/vuetify-overrides'

.SvgIcon--monochrome
  &, path, *
    &[fill]:not([fill="none"]):not([fill="transparent"])
      fill: currentColor !important
    &[stroke]:not([stroke="none"]):not([stroke="transparent"])
      stroke: currentColor !important
</style>

import { schema } from 'normalizr'

export const UserGroup = new schema.Entity('userGroups')
export const User = new schema.Entity('users', {
  userGroups: [UserGroup],
})
UserGroup.define({ authorCreated: User })
export const BotUser = new schema.Entity('bots')
export const Project = new schema.Entity('projects')
export const Card = new schema.Entity('cards')
export const Dashboard = new schema.Entity('dashboards', {
  cards: [Card],
})
export const Issue = new schema.Entity('issues')

<template>
  <v-textarea
    class="AppTextarea"
    :class="{ ...stateClasses, ...marginsClasses }"
    v-bind="$attrs"
    :hide-details="shouldHideDetails"
    :hint="hint"
    :error="error"
    :messages="messages"
    :error-messages="errorMessages"
    :rules="rules"
    v-on="$listeners"
  >
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
    <template #prepend>
      <slot name="prepend" />
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
  </v-textarea>
</template>

<script>
import appInput from '../mixins/appInput'

export default {
  name: 'AppTextarea',

  mixins: [appInput],

  inheritAttrs: false,

  props: {
    maxHeight: { type: Number, default: null },
  },

  watch: {
    maxHeight: { handler: 'setMaxHeightStyles', immediate: true },
  },

  beforeDestroy() {
    this.setMaxHeightStyles(null, false)
  },

  methods: {
    setMaxHeightStyles(maxHeight, nextTick = true) {
      if (nextTick) {
        return this.$nextTick(() =>
          this.setMaxHeightStyles(maxHeight, false))
      }

      const textarea = this.$el.querySelector('textarea')
      if (!textarea) return
      if (maxHeight && maxHeight > 0) {
        textarea.style.maxHeight = `${maxHeight}px`
        textarea.style.overflow = 'hidden auto'
      } else {
        textarea.style.maxHeight = null
        textarea.style.overflow = null
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/mixins'

.AppTextarea
  &.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
    ::v-deep textarea
      margin-top: 8px
      line-height: 20px
  &--disabled
    @include input-disabled()
</style>

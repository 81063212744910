export default {
  inheritAttrs: false,

  props: {
    marginsWithHiddenDetails: { type: String, default: 'mb-4' },
    marginsWithDetails: { type: String, default: '' },
    hint: { type: String, default: '' },
    hideDetails: { type: Boolean, default: false },
    transparentBorders: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    messages: { type: [String, Array], default: () => [] },
    errorMessages: { type: [String, Array], default: () => [] },
    rules: { type: Array, default: () => [] },
  },

  computed: {
    shouldHideDetails() {
      const { hideDetails, hint, rules, error, messages, errorMessages } = this
      return hideDetails || (
        !hint.length &&
        !error &&
        !messages?.length &&
        !errorMessages?.length &&
        !(rules || []).filter(rule => rule(this.$attrs.value ?? this.value) !== true).length
      )
    },

    marginsClasses() {
      const { marginsWithDetails, marginsWithHiddenDetails, shouldHideDetails } = this
      return shouldHideDetails
        ? { [marginsWithHiddenDetails]: true }
        : { [marginsWithDetails]: true }
    },

    stateClasses() {
      const { disabled } = this.$attrs
      const { transparentBorders } = this
      return {
        [`${this.$options.name}--disabled`]: !!disabled,
        [`${this.$options.name}--transparent-borders`]: !!transparentBorders,
      }
    },
  },
}

import axiosLib from 'axios'
import * as R from 'ramda'

import { axiosInstance as axios } from '@/api'
import { DEFAULT_ENVIRONMENT } from '@/constants'
import { loadLanguageAsync } from '@/i18n'

// import { ENVIRONMENT_MOCK } from '../../../tests/fixtures'

const initState = () => ({
  demoUsers: null,
  environment: null,
  selectedLocale: 'en',
})

const getters = {
  GET: state => state.environment,
  HAWSER_ENABLED: state => state.environment && state.environment.capabilities.hawser && state.environment.capabilities.hawser === 'ENABLED',
  REGISTRATION_ENABLED: state => state.environment && state.environment.capabilities.users.registration,
  CONFIRMATION_ENABLED: state => state.environment && state.environment.capabilities.users.confirmation,
  RTL_SWITCHER_ENABLED: state => state.environment && state.environment.capabilities.rtlSwitcher?.toLowerCase?.() === 'on',
}

const mutations = {
  reset: state => Object.assign(state, initState()),

  setDemoUsers: (state, demoUsers = null) => {
    state.demoUsers = demoUsers
  },

  setEnvironment: (state, { environment }) => {
    state.environment = environment
  },

  setSelectedLocale: (state, { selectedLocale }) => {
    state.selectedLocale = selectedLocale
  },
}

const actions = {
  getDemoUsers: (ctx, { reload = false } = {}) => {
    const { state, commit } = ctx
    return !reload && state.demoUsers != null
      ? Promise.resolve(undefined)
      : (
        axios.get('/ext/stand-demo-users.json', { baseURL: '/' })
          .then(({ data: users }) => commit('setDemoUsers', users))
          .catch(() => {}) // fail silently
      )
  },

  getEnvironment: ({ dispatch, commit, getters }, { reload = false } = {}) =>
    !reload && getters.GET != null
      ? Promise.resolve(getters.GET)
      : axiosLib.get('/ext/environment')
        .then(response => response.data)
        .catch(() => DEFAULT_ENVIRONMENT)
        .then(environment => {
          const languages = [...(environment.product?.languages || [])]
          if (!languages.some(lang => lang.name === 'en')) languages.push({ name: 'en', displayName: 'English' })
          const updatedEnv = R.set(R.lensPath(['product', 'languages']), languages, environment)
          commit('setEnvironment', { environment: updatedEnv })
          return languages
        })
        .then(languages => {
          const localStorageLanguage = localStorage.getItem('appLanguage')
          const locale = languages.find(lang => lang.name === localStorageLanguage) ? localStorageLanguage : 'en'
          commit('setSelectedLocale', { selectedLocale: locale })
          return dispatch('getLanguage', { locale })
            .then(() => getters.GET)
        }),

  getLanguage: (ctx, { locale }) => { // does not fail, returns bool (true - loaded succesfully)
    const { commit } = ctx
    return loadLanguageAsync(locale)
      .then(() => {
        commit('setSelectedLocale', { selectedLocale: locale })
        return true
      })
      .catch(e => {
        console.error('Cannot load desired locale:', e)
        commit('setSelectedLocale', { selectedLocale: 'en' })
        return false
      })
  },

}

export const watch = store => {
  const unsubscribe = store.subscribeAction({
    after: (action, state) => {
      if (action.type === 'ext/getLanguage') {
        localStorage.setItem('appLanguage', state.ext.selectedLocale)
      }
    },
  })
  return () => {
    unsubscribe()
  }
}

export default {
  namespaced: true,
  state: initState,
  getters,
  mutations,
  actions,
}

<template>
  <v-text-field
    ref="vuetifyTextField"
    class="AppTextField"
    :class="{ ...stateClasses, ...marginsClasses }"
    v-bind="$attrs"
    :hide-details="shouldHideDetails"
    :hint="hint"
    :error="error"
    :messages="messages"
    :error-messages="errorMessages"
    :rules="rules"
    v-on="$listeners"
  >
    <template
      v-if="$scopedSlots.append"
      #append
    >
      <slot name="append" />
    </template>
    <template
      v-if="$scopedSlots['append-outer']"
      #append-outer
    >
      <slot name="append-outer" />
    </template>
    <template
      v-if="$scopedSlots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template
      v-if="$scopedSlots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner" />
    </template>
  </v-text-field>
</template>

<script>
import appInput from '../mixins/appInput'

export default {
  name: 'AppTextField',

  mixins: [appInput],

  inheritAttrs: false,

  methods: {
    // public methods: propagating methods to vuetify, etc
    focus(...args) {
      this.$refs.vuetifyTextField.focus(...args)
    },
    select() {
      this.$refs.vuetifyTextField.$refs.input.select()
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/mixins'

.AppTextField
  //&.v-text-field.v-text-field--filled.v-text-field--enclosed
  //  ::v-deep input
  //    padding-top: 8px
  &--transparent-borders ::v-deep fieldset
    border-color: transparent !important
  &--disabled
    @include input-disabled()
</style>

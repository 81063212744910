import { Model } from '@vuex-orm/core'

import { UserRoleValueSchema as USER_ROLE, ServiceService } from '../../api'
import { handleError } from '../../helpers'
import i18n from '../../i18n'

export class UserRole extends Model {
  static entity = 'userRole'
  static primaryKey = 'name'

  static DEFAULT_ROLE = USER_ROLE.EDITOR
  static ICON = Object.freeze({
    [USER_ROLE.ADMIN]: 'mdi-wrench-cog-outline',
    [USER_ROLE.EDITOR]: 'mdi-square-edit-outline',
    [USER_ROLE.READONLY]: '$eye',
  })

  static ORDER = Object.freeze([
    USER_ROLE.ADMIN,
    USER_ROLE.EDITOR,
    USER_ROLE.READONLY,
  ])

  static fields() {
    return {
      name: this.string(USER_ROLE.EDITOR),
      displayName: this.string(''),
      permissions: this.attr([]),
    }
  }

  get icon() {
    return UserRole.ICON[this.name]
  }

  static getOrderedQuery() {
    return UserRole.query().orderBy(role => UserRole.ORDER.indexOf(role.name))
  }
}

export const UserRoleModule = {
  actions: {
    async $get(ctx, { reload = false } = {}) {
      if (!reload && UserRole.query().exists()) return
      try {
        const roles = await ServiceService.getUserRoles()
        await UserRole.create({ data: roles })
      } catch (e) {
        await handleError(ctx, e, i18n.t('user.ErrorFetchingUserRolesM'))
      }
    },
  },
}

export default UserRole

import { Model } from '@vuex-orm/core'

import { wait } from '../../helpers'

let idSource = 0

const onCloseMap = new Map()

export class Dialog extends Model {
  static entity = 'dialog'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.uid(() => ++idSource),
      componentName: this.string(null).nullable(),
      props: this.attr({}),
      listeners: this.attr({}),
      isOpen: this.boolean(true),
      closeAnimationDuration: this.number(500),
      persistRouteChanges: this.boolean(false),
    }
  }

  /**
   * Default way to open dialog
   * @param componentName - component name registered in `DialogRoot.vue`, e.g. 'MyDialog'
   * @param props - object with component props, e.g.: { projectId: '12345-...' }
   * @param listeners - object with listeners, e.g.: { 'save': (id) => alert('Modal saved something with id ' + id) }
   * @returns {Promise<Dialog>}
   */
  static async open({ componentName, props = {}, listeners = {}, onClose = null, ...rest }) {
    const dialog = new Dialog({
      componentName,
      props,
      listeners,
      ...rest,
    })
    await dialog.$save()
    if (onClose) onCloseMap.set(dialog.id, onClose)
    return dialog
  }

  async close() {
    await this.$update({ isOpen: false })
    onCloseMap.get(this.id)?.(this)
    await wait(this.closeAnimationDuration)
    // dialog isn't deleted right away in order not to disturb closing animation
    if (!this.$find(this.id)?.isOpen) await this.$delete()
  }

  onDialogModelInput(isOpen) {
    if (isOpen) this.$update({ isOpen: true })
    else this.close().catch(e => console.error(e))
  }
}

export default Dialog

import VuexORM from '@vuex-orm/core'

import {
  Dashboard,
  DashboardModule,
  DashboardCard,
  DashboardCardModule,
  Dialog,
  Issue,
  IssueModule,
  IssueComment,
  IssueCommentModule,
  IssueCounter,
  IssueCounterModule,
  IssueExportRecord,
  IssueStatusChange,
  IssueStatusChangeModule,
  Project,
  ProjectModule,
  ProjectGroup,
  ProjectGroupModule,
  SlaSettings,
  SlaConfig,
  SlaConfigModule,
  UserGroup,
  UserGroupModule,
  UserRole,
  UserRoleModule,
} from './_models'

const database = new VuexORM.Database()

for (const registerArgs of [
  [Dashboard, DashboardModule],
  [DashboardCard, DashboardCardModule],
  [Dialog],
  [Issue, IssueModule],
  [IssueComment, IssueCommentModule],
  [IssueCounter, IssueCounterModule],
  [IssueExportRecord],
  [IssueStatusChange, IssueStatusChangeModule],
  [Project, ProjectModule],
  [ProjectGroup, ProjectGroupModule],
  [SlaSettings],
  [SlaConfig, SlaConfigModule],
  [UserGroup, UserGroupModule],
  [UserRole, UserRoleModule],
]) database.register(...registerArgs)

export default database

if (process.env.NODE_ENV === 'development') {
  window.$db = database
}

import { Model } from '@vuex-orm/core'
import * as R from 'ramda'

import { UserGroupService } from '@/api'
import { handleError } from '@/helpers'

export class UserGroup extends Model {
  static entity = 'userGroup'
  static primaryKey = 'id'

  static fields() {
    return {
      id: this.string(null).nullable(),
      name: this.string(''),
      description: this.string(''),

      created: this.string(null).nullable(),
      updated: this.string(null).nullable(),

      // field: `authorCreated`, it should be a rel to a user, but users aren't in ORM yet
      authorId: this.string(null).nullable(),

      _searchBy: this.string(''),
    }
  }

  static commitGroup(group) {
    if (R.is(String, group.authorCreated)) {
      group.authorId = group.authorCreated
    } else if (R.is(Object, group.authorCreated)) {
      const store = UserGroup.store()
      store.commit('user/setOne', { user: R.clone(group.authorCreated) })
      group.authorId = group.authorCreated.id
    }

    group._searchBy = [group.name, group.description]
      .filter(Boolean)
      .join(' ')
      .toLocaleLowerCase()
    return UserGroup.insertOrUpdate({ data: group })
  }
}

export const UserGroupModule = {
  actions: {
    $getList: (ctx, { reload = true } = {}) =>
      reload || !UserGroup.query().exists()
        ? UserGroupService.getUserGroups()
          .then(groups => groups
            .forEach(g => UserGroup.commitGroup(g)))
          .catch(e => handleError(ctx, e, 'Error loading user groups'))
        : undefined,

    $create: (ctx, { userGroup }) =>
      UserGroupService.postUserGroup({
        body: R.omit(['id', 'authorId', 'authorCreated', 'created', 'updated'], userGroup),
      })
        .then(group => UserGroup.commitGroup(group).then(() => group.id))
        .catch(e => handleError(ctx, e, 'Error creating user group')),

    $update: (ctx, { userGroup }) =>
      UserGroupService.patchUserGroup({
        userGroupId: userGroup.id,
        body: R.omit(['id', 'authorId', 'authorCreated', 'created', 'updated'], userGroup),
      })
        .then(group => UserGroup.commitGroup(group).then(() => group.id))
        .catch(e => handleError(ctx, e, 'Error saving user group')),

    $delete: (ctx, { userGroupId }) =>
      UserGroupService.deleteUserGroup({ userGroupId })
        .then(() => UserGroup.delete(userGroupId))
        .catch(e => handleError(ctx, e, 'Error deleting user group')),

    $addUsers: (ctx, { userGroupId, userIds }) =>
      UserGroupService.setUsersIntoUserGroup({
        userGroupId,
        body: { users: userIds },
      })
        .then(() => userIds.forEach((userId) => ctx.commit(
          'user/addToGroup',
          { userId, userGroupId },
          { root: true },
        )))
        .catch(e => handleError(ctx, e, 'Error adding users to group')),

    $removeUsers: (ctx, { userGroupId, userIds }) =>
      UserGroupService.unsetUsersFromUserGroup({
        userGroupId,
        body: { users: userIds },
      })
        .then(() => userIds.forEach((userId) => ctx.commit(
          'user/removeFromGroup',
          { userId, userGroupId },
          { root: true },
        )))
        .catch(e => handleError(ctx, e, 'Error adding users to group')),
  },
}

export default UserGroup

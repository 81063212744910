<template>
  <v-select
    ref="vSelect"
    class="AppSelect"
    :class="{ ...stateClasses, ...marginsClasses }"
    v-bind="$attrs"
    :hide-details="shouldHideDetails"
    :hint="hint"
    :error="error"
    :messages="messages"
    :error-messages="errorMessages"
    :rules="rules"
    v-on="$listeners"
  >
    <template
      v-if="$scopedSlots.item"
      #item="slotScope"
    >
      <slot
        name="item"
        v-bind="slotScope"
      />
    </template>
    <template
      v-if="$scopedSlots.selection"
      #selection="slotScope"
    >
      <slot
        name="selection"
        v-bind="slotScope"
      />
    </template>
    <template
      v-if="$scopedSlots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template
      v-if="$scopedSlots['prepend-item']"
      #prepend-item
    >
      <slot name="prepend-item" />
    </template>
  </v-select>
</template>

<script>
import appInput from '../mixins/appInput'

export default {
  name: 'AppSelect',

  mixins: [appInput],

  inheritAttrs: false,

  methods: {
    focus() { this.$refs.vSelect.focus() },
    open() {
      this.focus()
      this.$refs.vSelect.isMenuActive = true
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/mixins'

.AppSelect
  //&.v-text-field.v-text-field--filled.v-text-field--enclosed
  //  ::v-deep input
  //    padding-top: 8px
  &--disabled
    @include input-disabled()
</style>

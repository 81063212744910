<template>
  <div
    class="LicenseStatusBar"
    :class="{ 'LicenseStatusBar--expired': isExpired }"
  >
    <v-icon :color="isExpired ? '#EF5350' : '#FF9800'">
      mdi-alert-outline
    </v-icon>

    <span
      class="ml-2 flex-1 overflow-x-hidden"
      style="text-overflow: ellipsis"
    >
      {{ $t('license.LicenseExpirationM', { expiration: isExpired ? $t('license.hasExpired') : $t('license.willExpire') }) }}
      {{ license && fmt(license.endDate, 'l') }}
    </span>
    <div class="LicenseStatusBar__right">
      <v-btn
        text
        tile
        class="LicenseStatusBar__right-btn"
        :color="isExpired ? '#EF5350' : '#38364D'"
        href="https://hexway.io/pricing/#contact-us"
        target="_blank"
      >
        {{ $t('license.ContactUs') }}
      </v-btn>
      <v-btn
        v-if="isAdmin && $route.name !== 'License'"
        text
        tile
        class="LicenseStatusBar__right-btn"
        :color="isExpired ? '#EF5350' : '#38364D'"
        :to="{ name: 'License' }"
      >
        {{ $t('license.UploadLicense') }}
      </v-btn>
      <v-btn
        class="ml-5"
        icon
        @click="$emit('close')"
      >
        <v-icon :color="isExpired ? '#EF5350' : '#38364D'">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { fmtDt } from '@/helpers'

export default {
  name: 'LicenseStatusBar',

  computed: {
    isAdmin() { return this.$store.getters['user/current']?.isAdmin },
    license() { return this.$store.state.license.current },
    isExpired() { return this.$store.getters['license/isExpired'] },
  },

  methods: {
    fmtDt,
    fmt(dt, fmt) { return moment(dt).format(fmt) },
  },
}
</script>

<style lang="sass" scoped>
.LicenseStatusBar
  position: absolute
  top: 0
  background: #FFECB3
  width: 100%
  left: 0
  height: 40px
  display: flex
  align-items: center
  padding: 0 24px
  z-index: 6
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  &--expired
    background: #FFEBEE
    color: #EF5350

  &__right
    margin-left: auto

    &-btn
      font-weight: 600
</style>
